import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { AppProvider, Banner, BlockStack, EmptyState, Action, Text } from '@shopify/polaris';
import towingSvg from '~/icons/towing.svg';
import polarisTranslations from '@shopify/polaris/locales/en.json';

export function ErrorBoundaryFallback({ action }: { action?: Action }) {
    const error = useRouteError();

    return (
        <AppProvider i18n={polarisTranslations}>
            <EmptyState image={towingSvg}>
                {isRouteErrorResponse(error) ? (
                    <Banner tone="critical" title={error.statusText || error.status.toString()} action={action}>
                        <Text as="p" variant="headingMd">
                            {error.data}
                        </Text>
                    </Banner>
                ) : (
                    <Banner tone="critical" title="Something went wrong">
                        <BlockStack gap="200">
                            <BlockStack gap="200">
                                <Text variant="bodyMd" as="h2">
                                    We encountered an error while trying to load this page.
                                </Text>
                                <Text variant="bodyMd" as="h2" fontWeight="semibold" tone="subdued">
                                    Please open the app again from the Shopify admin sidebar.
                                </Text>
                            </BlockStack>
                        </BlockStack>
                    </Banner>
                )}
            </EmptyState>
        </AppProvider>
    );
}
